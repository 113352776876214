<template>
    <div class="top-color-ground">
        <nav class="navbar navbar-expand-lg">
            <div class="container-fluid">
                <a class="navbar-brand" href="#">
                    <img src="../../assets/logo_web.png" alt="" width="200" class="d-inline-block align-text-top">
                </a>
                <div class="collapse navbar-collapse">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    </ul>
                    <div class="d-flex" role="search">
                       <button class="btn btn-default" @click="direct()">
                            <font-awesome-icon :icon="['fas', 'headset']" class="top-color mr-2" size="sm"/>
                            <span class="fontW top-color">SUPPORT</span>
                       </button>
                       <button class="btn btn-dark" @click="signInOpen()">
                         <span class="fontW text-white">SIGN IN</span>
                       </button>
                    </div>
                </div>
            </div>
        </nav>
        <signinform ref="sModal"></signinform>
        <div class="container">
            <div class="row">
                <div class="col-5">
                    <div class="fs-3 text-white pt-3">Welcome,</div>
                    <div class="fs-5 text-white">
                        welcome to An application that <br/>
                        helps you manage assets and <br/>
                        income and expenses  <br/>
                        systematically.
                    </div>
                    <div class="mt-3">
                        <div class="fs-4 text-white">Social Authentication</div>
                        <br/>
                        <div class="row text-white">
                            <div class="col-2 text-center">
                                <div>
                                    <font-awesome-icon :icon="['fab', 'facebook']" size="2x"/>
                                </div>
                                <div class="fs-6">Facebook</div>
                            </div>
                            <div class="col-2 text-center">
                                <div>
                                    <font-awesome-icon :icon="['fab', 'google']" size="2x"/>
                                </div>
                                <div class="fs-6">Google</div>
                            </div>
                            <div class="col-2 text-center">
                                <div>
                                    <font-awesome-icon :icon="['fab', 'apple']" size="2x"/>
                                </div>
                                <div class="fs-6">Apple</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-5">
                            <button class="btn btn-dark">
                                <div class="row">
                                    <div class="col-sm-3 pt-2">
                                        <font-awesome-icon :icon="['fab', 'app-store']" class="text-white" size="2x"/>
                                    </div>
                                    <div class="col-sm-8">
                                        <div class="row">
                                          <div class="col-sm-12 left-display f-small text-left">
                                             Available on the 
                                           </div>
                                           <div class="col-sm-12 left-display b text-left">
                                              App Store
                                           </div>
                                        </div>
                                    </div>
                                </div>
                            </button> 
                        </div>
                        <div class="col-5">
                                <button class="btn btn-success">
                                    <div class="row">
                                        <div class="col-sm-3 pt-2">
                                            <font-awesome-icon :icon="['fab', 'google-play']" class="text-white" size="2x"/>
                                        </div>
                                        <div class="col-sm-8">
                                            <div class="row">
                                                <div class="col-sm-12 left-display f-small text-left">
                                                    Android app on 
                                                </div>
                                                <div class="col-sm-12 left-display b text-left">
                                                    Play Store
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                </div>
                <div class="col-7">

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import signinform from "../tipexpress/signinform.vue" 
export default {
    components:{
        signinform
    },
    methods:{
        signInOpen(){
            this.$refs.sModal.showModal();
        },
        direct(){
            window.location = "/support";
        }
    }
}
</script>
<style scoped>
 .f-small{
  font-size: 14px;
 }
 .fontW{
    font-weight: normal;
    margin-left: 7px;
    font-size: 12px;
 }
 .top-color{
    color: #FFCF5E;
 }
 .top-color-ground{
    background: #005C8D;
    position: relative;
    height: 500px;
    display: block;
 }
 .text-left{
    text-align: left;
 }
</style>