<template>
    <div id="main-home">
        <tkiatop></tkiatop>
        <tipleft></tipleft>
        <div ref="main-content" class="content-form">
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
import tipleft from "../tipexpress/tipleft"
import tkiatop from "../tipexpress/tkiatop.vue"
export default {
    components: {
        tipleft,
        tkiatop,
    }
}
</script>
<style scoped>
.content-form {
 padding-left: 90px;
}
</style>