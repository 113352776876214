<template>
    <div id="lef" class="vheight">
        <ul class="nav flex-column">
            <li class="nav-item p-2" style="line-height:30px;">
                <div class="p-2 d-block i-padding">
                    <span class="ppp rounded-circle"><img src="@/assets/mortgage.svg" class="ibal" /></span>
                </div>
            </li>
            <li class="nav-item p-2" style="line-height:30px;">
                <div class="p-2 d-block">
                    <span class="ppp rounded-circle"><img src="@/assets/funds.svg" class="ibal" /></span>
                </div>
            </li>
            <li class="nav-item p-2" style="line-height:30px;">
                <div class="p-2 d-block">
                    <span class="ppp rounded-circle"><font-awesome-icon icon="satellite-dish" size="lg"  style="color:#ffffff;width:27px;height:27px;" /></span>
                </div>
            </li>
            <li class="nav-item p-2" style="line-height:30px;">
                <div class="p-2 d-block">
                    <span class="ppp rounded-circle"><font-awesome-icon icon="users-cog" size="lg"  style="color:#51BCD0;width:27px;height:27px;" /></span>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
    .ibal {
        width:27px;
        height:27px;
    }
    .ltop {
        white-space: nowrap;
        display:block;
        position:relative;
        line-height:30px;
        background: rgba(0,0,0,0.5) !important;
    }
    .ppp {
        padding: 10px 7px;
        /* background: rgba(255,255,255,0.8); */
    }
    .vheight {
        height: 100vh;
        width: 75px;
        text-align: center;
        /*background: #272727;*/
        position: fixed;
    }
    .i-padding {
        padding: 10px 15px;
    }
</style>